<template>
  <ul class="rootlist">
    <slot />
  </ul>
</template>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  margin-left: 0px;
  margin-bottom: 0px;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  max-height: 280px;
}

ul.rootlist::-webkit-scrollbar {
	width: 13px;
	left: 100px;
}

ul.rootlist::-webkit-scrollbar-track {
	/* background: #ddd; */
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* box-shadow: inset 0 0 6px rgba(247, 243, 243, 0.7); */
	/* border-radius: 10px; */
	/* border: 2px solid #fff; */
}

ul.rootlist::-webkit-scrollbar-thumb {
	border: 3px solid #fff;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	background-color: #000;
	/* box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.7); */
}
</style>