<template>
    <component
        v-if="type !== 'router-link'"
        :is="type"
        :href="href"
        class="button"
        :id="id"
        :type="typeButton"
    >
        <slot />
        <span class="icon"><slot name="righticon" /></span>
    </component>
    <router-link v-else :to="to">
        <slot />
    </router-link>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: null,
        },
        to: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        typeButton: {
            type: String,
            default: null,
        },
    },
    computed: {
        type() {
            if (this.to) {
                return "router-link";
            }
            if (this.href) {
                return "a";
            } else {
                return "button";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    position: relative;
}

.icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btn-default {
    font-family: Montserrat Light;
    border-radius: 0;
    font-weight: bold;
    font-size: 14px;
    border: 0;
    text-align: center;
}

.btn-black {
    background-color: #0f0f1e;
    color: #fff;
}

.btn-green {
    background-color: #008c00;
    color: #fff;
    border: none;
}

a.btn:hover {
    color: #fff;
}

.btn-red {
    background-color: #990000;
    color: #fff;
}

.btn-large {
    padding: 0px 25px 0 25px !important;
    margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    width: 100%;
}

.comanda-rapida {
    // background-image: url('~@/assets/images/cere_oferta_btn.png');
    background-repeat: no-repeat;
    background-position: 5px center;
}

.comanda-rapida-form {
    font-family: "Montserrat SemiBold";
    font-size: 14px;
}

.btn-border-radius {
    border-radius: 25px !important;
}

// .tech-btn {
//   background-image: url("~@/assets/images/tech_btn.png");
//   background-repeat: no-repeat;
//   background-position: 10px center;
// }

.close-btn {
    position: absolute;
    top: 0;
    right: 0px;
    color: #6d6b6b !important;
    z-index: 10;
    opacity: 1;
    width: 65px;
    height: 65px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 30px;
    font-family: Raleway;
}

.minicart-btn {
    height: 52px;
    line-height: 52px;
}

.filter-mobile {
    border-radius: 30px;
    width: 90%;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 auto;
    height: 60px;
}

@media (max-width: 576px) {
    .tech-btn {
        font-family: "Montserrat SemiBold";
        font-size: 12px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .comanda-rapida {
        font-family: "Montserrat SemiBold";
        font-size: 12px;
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
