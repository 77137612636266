<template>
  <div class="mini-cos" id="mini-cos">
    <li class="header-mini-cos">
      <p class="small">Produse adaugate recent:</p>
    </li>

    <mini-cart-products-list>
      <mini-cart-product-card
        v-for="(item, index) in cartData.positions"
        :key="index"
        :id="item.product.id"
        :image="item.product.thumbnail"
        :path="item.path"
        :quantity="item.quantity"
        :price="item.price_per_unit"
        :euro="priceInEuro(item.price_per_unit_value)"
        :offerId="item.offer.id"
        :item="item"
      />
    </mini-cart-products-list>

    <div class="wrp-footer-cos">
      <div class="footer-cos load-fc d-flex">
        <div class="col-sm-6">
          <span v-if="cartData.quantity > 0"
            ><strong>Total:</strong> {{ cartData.quantity }} produse</span
          >
          <span v-else><strong>Total:</strong> 0 produse</span>
        </div>
        <div class="col-sm-6">
          <div v-if="cartData.quantity > 0" class="total-value">
            {{ cartData.total_price.price }} Lei
          </div>
          <div v-else class="total-value">0 Lei</div>
          <div class="footer-valuta valuta-price">
            ( {{ priceInEuro(cartData.total_price.price_value) }} € )
          </div>
        </div>
      </div>

      <pf-button
        :href="'/cos-cumparaturi'"
        :class="'btn btn-default btn-green btn-large mb-0 minicart-btn'"
      >
        Spre finalizare comanda
        <template v-slot:righticon>
          <font-awesome-icon icon="angle-right" />
        </template>
      </pf-button>
    </div>
  </div>
</template>

<script>
import MiniCartProductsList from "@/components/header/minicart/MiniCartProductsList.vue";
import MiniCartProductCard from "@/components/header/minicart/MiniCartProductCard.vue";
import PfButton from "@/components/buttons/PfButton.vue";
import { shopMixin } from "@/mixins/shopMixin";
import { mapGetters } from "vuex";

export default {
  components: {
    MiniCartProductsList,
    MiniCartProductCard,
    PfButton,
  },
  mixins: [shopMixin],
  computed: {
    ...mapGetters("shop", ["cartData"]),
  },
};
</script>

<style scoped>
.mini-cos {
  width: 385px;
  background-color: #fff;
  z-index: 12;
  position: absolute;
  right: 1px;
  top: 54px;
  -moz-border-radius: 5px;
  -moz-border-radius-topright: 0;
  cursor: default;
}

.header-mini-cos {
  border-bottom: 1px solid #ddd;
  color: #999;
}
.mini-cos li.header-mini-cos {
  height: 35px;
  display: inline-block;
  width: 100%;
  padding-left: 10px;
}
.header-mini-cos p {
  margin: 6px 0 10px 0;
}

.mini-cos .total-cos {
  height: 25px;
  font-weight: bold;
  border-top: 1px solid #ddd;
  padding-top: 5px;
  background-color: #ded9d3;
}

.mini-cos .footer-cos {
  height: auto;
  background-color: #ded9d3;
  width: 100%;
  border: none;
}

.footer-cos div span:first-child {
  text-transform: uppercase;
  font-size: 12px;
  font-family: Montserrat SemiBold;
  line-height: 15px;
  text-align: left;
}

.footer-cos div span:nth-child(2) {
  float: right;
  color: #b60000;
  font-size: 14px;
  font-family: Montserrat Regular;
  line-height: 1;
  text-transform: uppercase;
}

.remove a:hover {
  text-decoration: none;
}

.footer-cos a {
  padding: 16px 12px;
}

.footer-cos > div {
  padding-top: 5px;
  padding-bottom: 5px;
}

.total-value {
  color: #b60000;
  font-size: 14px;
  font-family: Montserrat Regular;
  line-height: 1;
  text-transform: uppercase;
  text-align: right;
}

.valuta-price {
  text-align: right;
  font-size: 12px;
  color: #b60000;
  font-family: Montserrat Regular;
}
</style>
