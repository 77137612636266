export const shopMixin = {
    methods: {
        priceInEuro(price) {
            let euro = this.$store.getters['shop/currency'];
            let priceInEuro = (price/euro.EUR).toFixed(2);
            priceInEuro = 0.01 * +priceInEuro + +priceInEuro;
            priceInEuro = priceInEuro.toFixed(2);
            return priceInEuro;
        },
        isPromotion(product) {
            if (product.offers.length) {
              if (product.offers[0].old_price_value > 0) {
                return true;
              }
            }
            return false;
        },
        isInStock(product) {
          if (product.sold_out === 1 || (product.has_related_parent && product.offers[0].quantity === 0) ) {
            return false;
          }
          return true;
        },
        isFullWidthContent(product) {
          let answer = false;
          if (product.layout_property.length) {
            let fullWidthContent = product.layout_property.filter(elem => {
              return elem.code === 'layout';
            });
            if(fullWidthContent.length) {
              answer = fullWidthContent[0].value === 'full-width-content';
            }
          }
          return answer;
        }
    }
  }