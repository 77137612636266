<template>
  <li :id="id">
    <div class="produs col-md-6 col-sm-6">
      <img :src="image" />

      <div class="produs-title">
        <a :href="path">
          {{ title }}
        </a>
      </div>
    </div>
    <div class="cantitate col-md-2 col-sm-2">
      <div class="remove">
        <a @click="removeFromMiniCart()" class="remove-item" :data-id="id">X</a>
      </div>
      <div class="qty-value">
        <div class="wrp-value">{{ quantity }}</div>
      </div>
    </div>
    <div class="pret-cos col-md-4 col-sm-4">
      {{ price }} Lei <span class="valuta-price">( {{ euro }} € )</span>
    </div>
  </li>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  ...mapGetters("shop", ["cartProducts"]),
  props: ["id", "image", "path", "item", "quantity", "price", "euro", "offerId"],

  computed: {
    title() {
      // console.log(this.item);
      if (
        Object.prototype.hasOwnProperty.call(this.item, "property") &&
        typeof this.item.property === "object" &&
        Object.prototype.hasOwnProperty.call(this.item.property, "custom-name")
      ) {
        // if (this.item?.property["custom-name"]) {
        return this.item.property["custom-name"];
      } else {
        return this.item.product.name;
      }
    },
  },

  methods: {
    removeFromMiniCart() {
      const payload = {
        offer_id: this.item.offer.id,
        position_id: this.item.id,
      };
      this.$store.dispatch("shop/removeProductFromCart", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  height: 90px;
  display: flex;
  align-items: center;

  &:not(.option-minicart) {
  }

  .produs {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;

    img {
      float: left;
      width: 55px;
    }

    .produs-title {
      float: left;
      font-weight: bold;
      padding-left: 5px;
      width: 100%;
      font-size: 14px;
      line-height: 13px;

      a {
        color: #000;
        font-family: Montserrat Light;
        font-size: 11px;
        text-transform: none;
      }
    }
  }

  .cantitate {
    font-size: 14px;
    color: #9f0000;
    display: flex;
    align-items: center;
    padding-left: 10px;

    .remove .remove-item {
      font-family: Montserrat Regular;
      font-size: 12px;
      float: left;
      cursor: pointer;
    }

    .qty-value {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .wrp-value {
        text-align: center;
      }
    }
  }

  .pret-cos {
    color: #000;
    padding-left: 5px;
    text-transform: uppercase;
    font-size: 14px;
    padding-right: 0;
    letter-spacing: -0.5px;

    .valuta-price {
      font-size: 12px;
      clear: both;
      color: #000;
      text-transform: uppercase;
      display: block;
    }
  }
}
</style>
